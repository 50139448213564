import { SplitTypeAnimation } from '../split_type_animation';
import { Select2 } from '../select2';
import { Hotspots } from '../hotspots';
import { Slick } from '../slick';
import { CardTrio } from '../card_trio';
import { BasecampAnimation } from '../basecamp-animation';
import { SlickTransition } from '../slick_transition';
class CougarMountain {
    constructor() {}

    init() {
        this.initSliders();
        new SplitTypeAnimation().init();
        new Select2();
        new Hotspots();
        new CardTrio();
        new BasecampAnimation().init();
        new SlickTransition(
            "[data-slider='gallery_slider']",
            document.querySelector('.gallery_slider__slider')
        ).init();
        new SlickTransition(
            "[data-slider='part-gallery_slider-image_only']",
            document.querySelector('#part-gallery_slider-image_only')
        ).init();
    }

    initSliders() {
        new Slick("[data-slider='gallery_slider']", {
            adaptiveHeight: true,
            speed: 0,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            swipe: true,
            infinite: true,
            dots: true,
            prevArrow: false,
            nextArrow: document.querySelector('.gallery_slider__slider'),
            dotsClass: 'custom_paging',
            touchThreshold: 250,
            customPaging: function (slider, i) {
                const curr = i + 1;
                if (i + 1 > 10) {
                    return (
                        '<span class="h1 current">' +
                        curr +
                        '</span>' +
                        '<span class="h1 total">/' +
                        slider.slideCount +
                        '</span>'
                    );
                } else {
                    const formattedNumber = curr.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    });
                    return (
                        '<span class="h1 current">' +
                        formattedNumber +
                        '</span>' +
                        '<span class="h1 total">/0' +
                        slider.slideCount +
                        '</span>'
                    );
                }
            },

            responsive: [
                {
                    breakpoint: 768,
                    variableWidth: true,
                    centerMode: true,
                    centerPadding: '15px',
                    speed: 0,
                    adaptiveHeight: false
                }
            ]
        });

        new Slick("[data-slider='part-gallery_slider-image_only']", {
            adaptiveHeight: true,
            speed: 300,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            useTransform: false,
            dots: true,
            prevArrow: false,
            nextArrow: document.querySelector(
                '.part-gallery_slider-image_only__slider'
            ),
            dotsClass: 'custom_paging',
            touchThreshold: 250,
            customPaging: function (slider, i) {
                const curr = i + 1;
                if (i + 1 > 10) {
                    return '<span>' + curr + '</span>';
                } else {
                    const formattedNumber = curr.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    });
                    return '<span>' + formattedNumber + '</span>';
                }
            }
        });

        new Slick(".slider[data-slider='card-trio']", {
            speed: 0,
            variableWidth: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            useTransform: false,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,
            draggable: false,
            swipe: true,
            initialSlide: 1
        });
    }
}

export { CougarMountain };
