import { BookingLinks } from './booking_links';
import { Slick } from './slick';
import { Draggable } from './draggable';
import { isTouchDevice } from './helpers';

class FyaEngineForm {
    constructor() {
        this.submit = document.querySelectorAll('[data-fya="submit"]');
        this.init();
    }

    init() {
        this.registerClickEvents();
        this.registerResizeEvent();
    }

    registerClickEvents() {
        this.submit.forEach((button) => {
            button.addEventListener(
                'click',
                function (e) {
                    this.ajaxPostFunction(e.target).catch((error) => {
                        console.log(error);
                    });
                }.bind(this)
            );
        });
    }

    sliderShift() {
        const slider = document.querySelector('.results[data-fya="results"]');
        const slides = slider.querySelectorAll('.result__card');

        if (
            !slider.classList.contains('slick-initialized') &&
            slides.length == 5
        ) {
            setTimeout(() => {
                slider.scrollBy({ left: 180, top: 0, behavior: 'smooth' });
            }, 50);
        }
    }

    registerResizeEvent() {
        if (!isTouchDevice()) {
            window.addEventListener(
                'resize',
                function (e) {
                    const slider = document.querySelector(
                        '.results[data-fya="results"]'
                    );

                    if (slider) {
                        setTimeout(() => {
                            if (
                                !slider.classList.contains('slick-initialized')
                            ) {
                                console.log('test');
                                this.ajaxPostFunction(
                                    this.registerResizeEvent
                                ).catch((error) => {
                                    console.log(error);
                                });
                            }
                        }, 1000);
                    }
                }.bind(this)
            );
        }
    }

    ajaxPostFunction() {
        return new Promise((resolve, reject) => {
            var filter = jQuery('#filter');
            var data = jQuery(filter).serializeArray();
            var sliderShift = this.sliderShift;

            jQuery.ajax({
                url: filter.attr('action'),
                data: jQuery.param(data),
                type: filter.attr('method'),
                dataType: 'json',
                success: function (results) {
                    if (
                        jQuery(
                            '.results-container[data-fya="results"]'
                        ).html() != results.html
                    ) {
                        jQuery(
                            '.results-container[data-fya="results"]'
                        ).fadeOut(100, function () {
                            jQuery(
                                '.results-container[data-fya="results"]'
                            ).html(results.html);
                            jQuery(
                                '.results-container[data-fya="results"]'
                            ).fadeIn();

                            new Slick('.results[data-fya="results"]', {
                                adaptiveHeight: true,
                                speed: 300,
                                variableWidth: true,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                infinite: true,
                                dots: false,
                                prevArrow: false,
                                nextArrow: false,
                                touchThreshold: 250,
                                centerMode: true,
                                centerPadding: '15px',
                                mobileFirst: true,
                                responsive: [
                                    {
                                        breakpoint: 900,
                                        settings: 'unslick'
                                    }
                                ]
                            });

                            sliderShift();

                            new Draggable().init();
                            new BookingLinks();

                            resolve(results);
                        });
                    }
                },

                error: function (error) {
                    reject(error);
                }
            });

            return false;
        });
    }
}

export { FyaEngineForm };
