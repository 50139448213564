class BookingLinks {
    constructor() {
        this.bookMenu = document.querySelectorAll('[data-index="book"]');
        this.bookLinks = document.querySelectorAll('[data-booking="trigger"]');
        this.init();
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.bookLinks.forEach((link) => {
            link.addEventListener(
                'click',
                function (e) {
                    const bookIndex =
                        e.target.parentNode.parentNode.dataset.index;
                    this.openMenu(bookIndex);
                }.bind(this)
            );
        });
    }

    openMenu(index) {
        this.bookMenu.forEach((item) => {
            const fyaPopup = document.querySelector('#popup-fya_engine');

            if (fyaPopup) {
                fyaPopup.classList.add('menu-open');
            }

            item.classList.add('menu-open');
            document.querySelector('body').classList.add('fixed');

            if (item.dataset.menu === 'menu') {
                const menuItem = item.querySelectorAll(
                    `[data-index="${index}"]`
                );
                const notMenuItem = item.querySelectorAll(`[data-index]`);
                notMenuItem.forEach((el) => el.classList.remove('menu-open'));
                menuItem.forEach((el) => el.classList.add('menu-open'));
            }
        });
    }
}

export { BookingLinks };
