class TabsActivity {
    constructor() {
        this.tabs = document.querySelectorAll('[data-tab="tab"]');
        this.init();
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        window.addEventListener('load', function () {});
        this.tabs.forEach((tab) => {
            tab.addEventListener('click', function (e) {
                const tabIndex = parseInt(
                    e.target.getAttribute('data-tab-index')
                );
                document
                    .querySelectorAll('[data-tab-index].active')
                    .forEach((item) => {
                        item.classList.remove('active');
                    });

                document
                    .querySelectorAll(`[data-tab-index="${tabIndex}"]`)
                    .forEach((item) => {
                        if (item.classList.contains('active')) {
                            item.classList.remove('active');
                        } else {
                            item.classList.add('active');
                        }
                    });
            });
        });
    }
}

export { TabsActivity };
