class Menus {
    constructor() {
        this.buttons = document.querySelectorAll('[data-menu="button"]');
        this.subbuttons = document.querySelectorAll('[data-menu="subbutton"]');
        this.utilityButtons = document.querySelectorAll(
            '[data-menu="utilityButton"]'
        );

        this.init();
    }

    init() {
        this.registerClickEvents();
    }

    registerClickEvents() {
        this.buttons.forEach((button) => {
            button.addEventListener('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
                const index = e.target.dataset.index;
                this.toggleMenu(index);
            });
        });

        this.subbuttons.forEach((subbutton) => {
            subbutton.addEventListener('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
                const index = e.target.dataset.index;
                this.toggleSubmenu(index);
            });
        });

        this.utilityButtons.forEach((utilButton) => {
            utilButton.addEventListener('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
                const index = e.target.dataset.index;
                const subindex = e.target.dataset.subindex;
                this.toggleUtilityMenu(index, subindex);
            });
        });
    }

    toggleMenu(index) {
        const menuPieces = document.querySelectorAll('[data-menu]');
        document.querySelector('body').classList.toggle('fixed');
        document.querySelectorAll('.item-active').forEach((item) => {
            item.classList.remove('item-active');
        });

        menuPieces.forEach((piece) => {
            const type = piece.dataset.menu;
            const fyaPopup = document.querySelector('#popup-fya_engine');

            if (piece.getAttribute('data-index') !== index) {
                piece.classList.remove('menu-open');

                if (fyaPopup) {
                    fyaPopup.classList.remove('menu-open');
                }

                switch (type) {
                    case 'button':
                        piece.setAttribute('aria-expanded', false);
                        break;

                    case 'subbutton':
                        break;

                    case 'utilityButton':
                        break;

                    default:
                        piece.setAttribute('aria-hidden', true);
                        break;
                }
            } else {
                piece.classList.toggle('menu-open');

                switch (type) {
                    case 'button':
                        if (piece.getAttribute('aria-expanded') === 'false') {
                            piece.setAttribute('aria-expanded', true);
                        } else {
                            piece.setAttribute('aria-expanded', false);
                        }
                        break;

                    case 'utilityButton':
                        if (piece.getAttribute('aria-expanded') === 'false') {
                            piece.setAttribute('aria-expanded', true);
                        } else {
                            piece.setAttribute('aria-expanded', false);
                        }
                        break;

                    case 'subutton':
                        if (piece.getAttribute('aria-expanded') === 'false') {
                            piece.setAttribute('aria-expanded', true);
                        } else {
                            piece.setAttribute('aria-expanded', false);
                        }
                        break;

                    default:
                        if (piece.getAttribute('aria-hidden') === 'false') {
                            piece.setAttribute('aria-hidden', true);
                            piece
                                .querySelectorAll('button')
                                .forEach((button) => {
                                    button.setAttribute('aria-hidden', true);
                                });
                        } else {
                            piece.setAttribute('aria-hidden', false);
                            piece
                                .querySelectorAll('button')
                                .forEach((button) => {
                                    button.setAttribute('aria-hidden', false);
                                });
                        }
                        break;
                }
            }
        });
    }

    toggleSubmenu(index) {
        const submenuPieces = document.querySelectorAll(
            '[data-menu="subbutton"], [data-menu="submenu"]'
        );

        submenuPieces.forEach((piece) => {
            const type = piece.dataset.menu;
            if (piece.getAttribute('data-index') !== index) {
                piece.classList.remove('menu-open');

                switch (type) {
                    case 'subbutton':
                        piece.setAttribute('aria-expanded', false);
                        break;

                    default:
                        piece.setAttribute('aria-hidden', true);
                        break;
                }
            } else {
                piece.classList.toggle('menu-open');

                switch (type) {
                    case 'subbutton':
                        if (piece.getAttribute('aria-expanded') === 'false') {
                            piece.setAttribute('aria-expanded', true);
                        } else {
                            piece.setAttribute('aria-expanded', false);
                        }
                        break;

                    default:
                        if (piece.getAttribute('aria-hidden') === 'false') {
                            piece.setAttribute('aria-hidden', true);
                        } else {
                            piece.setAttribute('aria-hidden', false);
                        }
                        break;
                }
            }
        });
    }

    toggleUtilityMenu(index, subindex) {
        const menuPieces = document.querySelectorAll('[data-menu]');
        document.querySelector('body').classList.toggle('fixed');

        menuPieces.forEach((piece) => {
            const type = piece.dataset.menu;

            if (piece.getAttribute('data-index') !== index) {
                piece.classList.remove('menu-open');

                switch (type) {
                    case 'button':
                        piece.setAttribute('aria-expanded', false);
                        break;

                    case 'subbutton':
                        break;

                    default:
                        piece.setAttribute('aria-hidden', true);
                        break;
                }
            } else {
                piece.classList.toggle('menu-open');
                document.querySelectorAll(`.${subindex}`).forEach((item) => {
                    item.classList.add('item-active');
                });

                switch (type) {
                    case 'utilityButton':
                        if (piece.getAttribute('aria-expanded') === 'false') {
                            piece.setAttribute('aria-expanded', true);
                        } else {
                            piece.setAttribute('aria-expanded', false);
                        }
                        break;

                    default:
                        if (piece.getAttribute('aria-hidden') === 'false') {
                            piece.setAttribute('aria-hidden', true);
                            piece
                                .querySelectorAll('button')
                                .forEach((button) => {
                                    button.setAttribute('aria-hidden', true);
                                });
                        } else {
                            piece.setAttribute('aria-hidden', false);
                            piece
                                .querySelectorAll('button')
                                .forEach((button) => {
                                    button.setAttribute('aria-hidden', false);
                                });
                        }
                        break;
                }
            }
        });
    }
}

export { Menus };
