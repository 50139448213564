import { SplitTypeAnimation } from '../split_type_animation';
import { Slick } from '../slick';
import { Values } from '../values';
import { FyaEngine } from '../fya_engine';
import { SlickTransition } from '../slick_transition';
import { DrawPath } from '../draw_path';

class About {
    contructor() {}

    init() {
        this.startAnimation();
        this.initSliders();

        new SplitTypeAnimation().init();
        new Values().init();
        new FyaEngine();
        new SlickTransition(
            "[data-slider='part-gallery_slider-image_only']",
            document.querySelector('#part-gallery_slider-image_only')
        ).init();
    }

    startAnimation() {
        if (!!window.IntersectionObserver) {
            let observer = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            new DrawPath(
                                document.querySelector(
                                    '#flourish-card_trio-desktop path'
                                ),
                                { duration: 8000 }
                            ).init();
                            new DrawPath(
                                document.querySelector(
                                    '#flourish-card_trio-mobile path'
                                ),
                                { duration: 8000 }
                            ).init();
                            observer.unobserve(entry.target);
                        }
                    });
                },
                { rootMargin: '0px 0px -200px 0px' }
            );
            observer.observe(document.querySelector('#card_trio-about'));
        }
    }

    initSliders() {
        new Slick("[data-slider='part-gallery_slider-image_only']", {
            adaptiveHeight: true,
            speed: 300,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            useTransform: false,
            dots: true,
            prevArrow: false,
            nextArrow: document.querySelector('.gallery_slider__slider'),
            dotsClass: 'custom_paging',
            touchThreshold: 250,
            customPaging: function (slider, i) {
                const curr = i + 1;
                if (i + 1 > 10) {
                    return '<span>' + curr + '</span>';
                } else {
                    const formattedNumber = curr.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    });
                    return '<span>' + formattedNumber + '</span>';
                }
            }
        });

        new Slick(".slider[data-slider='card-trio']", {
            speed: 0,
            variableWidth: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            useTransform: false,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,
            draggable: false,
            swipe: true,
            initialSlide: 1,
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        draggable: true
                    }
                }
            ]
        });
    }
}

export { About };
