import SplitType from 'split-type';
import { isTouchDevice } from './helpers';

class SplitTypeAnimation {
    constructor() {
        this.animations = document.querySelectorAll(
            '[data-animation="splitType"]'
        );
        this.lineAnimations = document.querySelectorAll(
            '[data-animation="splitTypeLine"]'
        );
    }

    init() {
        this.registerResizeListener();

        // Set delay for styles to load before breaking up the words
        setTimeout(() => {
            this.registerSplitType();
            this.registerSplitTypeLine();
        }, 500);
    }

    registerScroll(element) {
        const obs = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const lines = element.querySelectorAll('.line');

                    lines.forEach((line) => {
                        line.classList.add('active');
                    });
                    observer.unobserve(entry.target);
                }
            });
        });

        obs.observe(element);
    }

    registerSplitType() {
        this.animations.forEach((animation) => {
            this.registerScroll(animation);
            new SplitType(animation, {
                split: 'words, lines',
                splitClass: 'split'
            });
            animation.classList.add('active');
        });
    }

    registerSplitTypeLine() {
        this.lineAnimations.forEach((animation) => {
            this.registerScroll(animation);
            new SplitType(animation, {
                split: 'lines',
                splitClass: 'split'
            });
        });
    }

    registerResizeListener() {
        if (!isTouchDevice()) {
            window.addEventListener(
                'resize',
                function () {
                    this.registerSplitType();
                }.bind(this)
            );
        }
    }
}

export { SplitTypeAnimation };
