import { SplitTypeAnimation } from '../split_type_animation';
import { TabsGroupCards } from '../tabs-group_cards';
import { Slick } from '../slick';
import { Accordion } from '../accordion';
import { Draggable } from '../draggable';

class GroupAdventures {
    contructor() {}

    init() {
        new SplitTypeAnimation().init();
        new TabsGroupCards().init();
        new Accordion();
        this.initSliders();
        new Draggable().init();
    }

    initSliders() {
        new Slick("[data-slider='part-gallery_slider-image_only']", {
            adaptiveHeight: true,
            speed: 300,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            useTransform: false,
            dots: true,
            prevArrow: false,
            nextArrow: document.querySelector('.gallery_slider__slider'),
            dotsClass: 'custom_paging',
            touchThreshold: 250,
            customPaging: function (slider, i) {
                const curr = i + 1;
                if (i + 1 > 10) {
                    return '<span>' + curr + '</span>';
                } else {
                    const formattedNumber = curr.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    });
                    return '<span>' + formattedNumber + '</span>';
                }
            }
        });

        new Slick(".slider[data-slider='card-trio']", {
            speed: 0,
            variableWidth: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            useTransform: false,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,
            draggable: false,
            swipe: true,
            initialSlide: 1
        });

        new Slick("[data-slider='events-slider']", {
            adaptiveHeight: true,
            speed: 300,
            variableWidth: true,
            centerMode: true,
            centerPadding: '15px',
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,

            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: 'unslick'
                }
            ]
        });
    }
}

export { GroupAdventures };
