class Hotspots {
    constructor() {
        this.select = document.querySelectorAll('[data-hotspot="select"]');
        this.spots = document.querySelectorAll(
            '[data-hotspot="component"] .desktop g'
        );
        this.heading = document.querySelector('[data-hotspot="heading"');
        this.component = document.querySelector('[data-hotspot="component"]');
        this.init();
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.select.forEach((input) => {
            jQuery(input).on('change', (e) => {
                const spot = e.target.value;

                this.component.classList.add('hotspot-active');

                if (spot === 'basecamp') {
                    this.component.classList.remove('hotspot-active');
                }

                if (document.querySelectorAll('.display')) {
                    document.querySelectorAll('.display').forEach((item) => {
                        item.classList.remove('display');
                    });
                }
                document.querySelectorAll(`.${spot}`).forEach((item) => {
                    item.classList.add('display');
                });
            });
        });

        this.spots.forEach((spot) => {
            spot.addEventListener('click', (e) => {
                const slug = spot.classList.value;

                if (spot.classList.contains('display')) {
                    this.component.classList.remove('hotspot-active');
                    this.component
                        .querySelectorAll('.initial')
                        .forEach((element) => {
                            element.classList.add('display');
                        });

                    if (document.querySelectorAll('.display')) {
                        document
                            .querySelectorAll('.display')
                            .forEach((item) => {
                                item.classList.remove('display');
                            });
                    }
                } else {
                    this.component.classList.add('hotspot-active');
                    if (document.querySelectorAll('.display')) {
                        document
                            .querySelectorAll('.display')
                            .forEach((item) => {
                                item.classList.remove('display');
                            });
                    }

                    document.querySelectorAll(`.${slug}`).forEach((slug) => {
                        slug.classList.add('display');
                    });
                }
            });
        });
    }
}

export { Hotspots };
