class TabsGroupCards {
    constructor() {
        this.component = document.querySelector('#tabs-group_cards');
        this.cards = this.component.querySelectorAll('[data-card="trigger"]');
        this.tabs = this.component.querySelectorAll('[data-tab="trigger"]');
        this.next = this.component.querySelectorAll('[data-tab="next"]');
        this.prev = this.component.querySelectorAll('[data-tab="prev"]');
        this.curr = 0;
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.cards.forEach((card) => {
            card.addEventListener(
                'click',
                function (e) {
                    const index = e.target.dataset.index;
                    const elements =
                        this.component.querySelectorAll('[data-index]');

                    this.component.classList.add('active');

                    elements.forEach((el) => {
                        if (el.dataset.index === index) {
                            el.classList.add('active');
                        } else {
                            el.classList.remove('active');
                        }
                    });
                }.bind(this)
            );
        });

        this.tabs.forEach((tab) => {
            tab.addEventListener(
                'click',
                function (e) {
                    const index = e.target.dataset.index;
                    const elements =
                        this.component.querySelectorAll('[data-index]');

                    this.curr = index;

                    elements.forEach((el) => {
                        if (el.dataset.index === index) {
                            el.classList.add('active');
                        } else {
                            el.classList.remove('active');
                        }
                    });
                }.bind(this)
            );
        });

        this.next.forEach((element) => {
            element.addEventListener(
                'click',
                function () {
                    const limit = this.cards.length - 1;
                    const newIndex =
                        this.curr < limit ? parseInt(this.curr) + 1 : 0;
                    const elements =
                        this.component.querySelectorAll('[data-index]');

                    elements.forEach((el) => {
                        if (el.dataset.index == newIndex) {
                            el.classList.add('active');
                        } else {
                            el.classList.remove('active');
                        }
                    });

                    this.curr = newIndex;
                }.bind(this)
            );
        });

        this.prev.forEach((element) => {
            element.addEventListener(
                'click',
                function () {
                    const limit = this.cards.length - 1;
                    const newIndex = this.curr > 0 ? this.curr - 1 : limit;
                    const elements =
                        this.component.querySelectorAll('[data-index]');

                    elements.forEach((el) => {
                        if (el.dataset.index == newIndex) {
                            el.classList.add('active');
                        } else {
                            el.classList.remove('active');
                        }
                    });

                    this.curr = newIndex;
                }.bind(this)
            );
        });
    }
}

export { TabsGroupCards };
