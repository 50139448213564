import { Select2 } from './select2';
import { isDevice } from './helpers';

class AsideCombo {
    constructor() {
        this.component = document.querySelector('#aside-book_combo');
        this.container = document.querySelector('.aside-book_combo__text');
        this.calendarContainer = document.querySelector(
            '.aside-book_combo__calendar'
        );
        this.tourButtons = document.querySelectorAll('[data-book="tour"]');
        this.tourSelect = document.querySelectorAll(
            '[data-book="select_tour"]'
        );
        this.tabButtons = document.querySelectorAll('[data-book="tab"]');
        this.prices = document.querySelectorAll('[data-book="prices"]');
        this.priceContainer = document.querySelectorAll(
            '[data-book="price_container"]'
        );
        this.open = this.component.querySelector('[data-book="open"]');
        this.tours = false;
    }

    init() {
        this.registerEvents();
        this.tourButtons.forEach((button) => {
            if (button.classList.contains('always-active')) {
                this.tours = false;
            } else {
                this.tours = true;
            }
        });
        this.activeIndex = document.querySelector('.calendar.active')
            ? document.querySelector('.calendar.active').dataset.index
            : 0;
        this.noTours();
        new Select2({ full: true });
    }

    noTours() {
        if (this.tours === false) {
            console.log('notours');
            const index =
                this.tourButtons[this.tourButtons.length - 1].dataset.index;

            this.prices.forEach((price) => {
                const pIndex = price.dataset.index;
                if (price.classList.contains('active')) {
                    price.classList.remove('active');
                } else {
                    if (pIndex === index) {
                        price.classList.add('active');
                    } else {
                        price.classList.remove('active');
                    }
                }
            });

            this.addCalendar(index);

            this.priceContainer.forEach((price) => {
                if (price.dataset.index === index) {
                    price.classList.add('selected');
                }
            });
        }
    }

    registerEvents() {
        this.tourButtons.forEach((button) => {
            button.addEventListener(
                'click',
                function (e) {
                    this.tourButtonsEvent(e);
                }.bind(this)
            );
        });

        this.tourSelect.forEach((select) => {
            jQuery(select).on(
                'select2:select',
                function (e) {
                    this.tourSelectEvent(e);
                }.bind(this)
            );
        });

        this.tabButtons.forEach((button) => {
            button.addEventListener(
                'click',
                function (e) {
                    this.insideTabButtonEvent(e);
                }.bind(this)
            );
        });

        // Mobile Open
        this.open.addEventListener(
            'click',
            function () {
                const calendar = document.querySelector(
                    `.calendar[data-index='${this.activeIndex}']`
                );

                const priceContainer = document.querySelector(
                    `[data-book="prices"][data-index="${this.activeIndex}"]`
                );

                if (this.component.classList.contains('active')) {
                    document
                        .querySelector('body')
                        .classList.remove('aside-active');
                    this.component.classList.remove('active');
                    this.open.querySelector('p').innerText = 'Book Tour';
                    if (
                        !document
                            .querySelector('.select2-container')
                            .classList.contains('select2-container--disabled')
                    ) {
                        jQuery('.select--tour').val('all').trigger('change');
                    }
                    this.clear();
                } else {
                    document
                        .querySelector('body')
                        .classList.add('aside-active');
                    document
                        .querySelectorAll('.calendar')
                        .forEach((calendar) =>
                            calendar.classList.add('inactive')
                        );

                    // Mobile Resets
                    calendar.classList.add('active');
                    calendar.classList.remove('inactive');
                    priceContainer.classList.add('active');
                    priceContainer
                        .querySelector(
                            `.pricing[data-index="${this.activeIndex}"]`
                        )
                        .classList.add('selected');
                    this.component.classList.add('active');
                    this.open.querySelector('p').innerText = 'close';
                }
            }.bind(this)
        );

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentBoxSize) {
                    const optionsHeight = document.querySelector(
                        '.aside-book_combo__text'
                    );
                    const prices = document.querySelector(
                        '.aside-book_combo__text--prices.desktop.active'
                    );

                    if (isDevice('tablet')) {
                        this.calendarContainer.style.marginBottom = '100px';

                        prices.style.top =
                            optionsHeight.offsetHeight +
                            entry.target.offsetHeight +
                            'px';
                    }
                }
            }
        });

        resizeObserver.observe(
            document.querySelector('.aside-book_combo__calendar')
        );
    }

    tourButtonsEvent(e) {
        const activity = e.target.dataset.activity;
        const index = e.target.dataset.index;
        const activityContainer = document.querySelector(
            `.aside-book_combo__text--container[data-activity="${activity}"]`
        );
        const activeArray = [activityContainer, this.component, this.container];
        console.log(activeArray);
        // Elements to make active
        activeArray.forEach((element) => element.classList.add('active'));

        if (e.target.classList.contains('active')) {
            this.clear();
        } else {
            e.target.classList.add('active');

            // Hide inactive tour buttons
            activityContainer
                .querySelectorAll('[data-book="tour"]')
                .forEach((button) => {
                    if (button != e.target) {
                        button.classList.add('inactive');
                    }
                });

            this.prices.forEach((price) => {
                const pActivity = price.dataset.activity;
                const pIndex = price.dataset.index;
                if (price.classList.contains('active')) {
                    price.classList.remove('active');
                } else {
                    if (pIndex === index && pActivity === activity) {
                        price.classList.add('active');
                    } else {
                        price.classList.remove('active');
                    }
                }
            });

            this.addCalendar(index);

            this.priceContainer.forEach((price) => {
                if (
                    price.dataset.index === index &&
                    price.dataset.activity === activity
                ) {
                    price.classList.add('selected');
                }
            });
        }
    }

    tourSelectEvent(e) {
        const index = e.target.value;
        this.clear();
        this.container.classList.add('active');
        this.component.classList.add('active');

        // Toggle Calendars
        this.removeCalendar();
        this.addCalendar(index);

        this.prices.forEach((element) => {
            element.classList.add('active');
        });

        this.priceContainer.forEach((price) => {
            if (price.dataset.index === index) {
                price.classList.add('selected');
            } else {
                price.classList.remove('selected');
            }
        });
    }

    insideTabButtonEvent(e) {
        const activity = e.target.dataset.activity;
        const index = e.target.dataset.index;

        this.clear();

        this.container.classList.add('active');
        this.component.classList.add('active');

        this.tourButtons.forEach((button) => {
            if (
                button.dataset.index === index &&
                button.dataset.activity === activity
            ) {
                button.classList.add('active');
                this.addCalendar(e.target.dataset.index);
            } else {
                button.classList.add('inactive');
            }
        });
    }

    addCalendar(index) {
        document
            .querySelectorAll('[data-fareharbor="calendar"]')
            .forEach((calendar) => {
                calendar.classList.add('inactive');
            });

        document
            .querySelector(`[data-fareharbor="calendar"][data-index="${index}"`)
            .classList.add('active');
        document
            .querySelector(`[data-fareharbor="calendar"][data-index="${index}"`)
            .classList.remove('inactive');
    }

    removeCalendar() {
        if (document.querySelector(`[data-fareharbor="calendar"].active`)) {
            document
                .querySelector(`[data-fareharbor="calendar"].active`)
                .classList.remove('active');
        }
    }

    clear() {
        const activeArray = [
            this.container,
            this.component,
            Array.from(this.component.querySelectorAll('.selected')),
            Array.from(this.component.querySelectorAll('.active')),
            Array.from(this.component.querySelectorAll('.inactive'))
        ];

        const cleanArray = activeArray.filter((n) => {
            return n !== null && typeof n !== 'undefined';
        });

        // Elements to clear active
        cleanArray.forEach((element) => {
            if (Array.isArray(element)) {
                element.forEach((item) => {
                    item.classList.remove('active');
                    item.classList.remove('inactive');
                    item.classList.remove('selected');
                });
            } else {
                element.classList.remove('active');
                element.classList.remove('inactive');
                element.classList.remove('selected');
            }
        });
    }
}

export { AsideCombo };
