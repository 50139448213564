class SlickTransition {
    constructor(slider, container) {
        this.slider = slider;
        this.container = container;
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        jQuery(this.slider).on(
            'beforeChange',
            function (event, slick, currentSlide, nextSlide) {
                let currPlus = this.container.querySelector(
                    `[data-slick-index="${currentSlide + 1}"]`
                );
                let nextPlus = this.container.querySelector(
                    `[data-slick-index="${nextSlide + 1}"]`
                );
                let currMinus = this.container.querySelector(
                    `[data-slick-index="${currentSlide - 1}"]`
                );
                let nextMinus = this.container.querySelector(
                    `[data-slick-index="${nextSlide - 1}"]`
                );

                if (currPlus) {
                    currPlus.classList.remove('next');
                }

                if (nextPlus) {
                    nextPlus.classList.add('next');
                }

                if (currMinus) {
                    currMinus.classList.remove('prev');
                }

                if (nextMinus) {
                    nextMinus.classList.add('prev');
                }

                if (currentSlide < nextSlide) {
                    this.container.classList.add('after-active');
                    this.container.classList.add('after');
                    this.container.classList.remove('before');

                    setTimeout(() => {
                        this.container.classList.remove('after-active');
                    }, 1500);
                } else {
                    this.container.classList.add('before-active');
                    this.container.classList.remove('after');
                    this.container.classList.add('before');

                    setTimeout(() => {
                        this.container.classList.remove('before-active');
                    }, 1500);
                }
            }.bind(this)
        );
    }
}

export { SlickTransition };
