// Helpers - to aid you on your journey.

const debounce = (method, delay) => {
    clearTimeout(method._tId);
    method._tId = setTimeout(function () {
        method();
    }, delay);
};

const isTouchDevice = () => {
    return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
};

const disableScroll = () => {
    document.body.classList.add('stop-scrolling');
};

const enableScroll = () => {
    document.body.classList.remove('stop-scrolling');
};

const isOdd = (num) => {
    return num % 2 === 1;
};

const isElementXPercentInViewport = function (el, percentVisible) {
    let rect = el.getBoundingClientRect(),
        windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

    return !(
        Math.floor(
            100 - ((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100
        ) < percentVisible ||
        Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) <
            percentVisible
    );
};

const isDevice = (device) => {
    let deviceClass = device === 'tablet' ? '.tablet-check' : '.mobile-check';
    return window.getComputedStyle(
        document.querySelector(`${deviceClass}`),
        null
    ).display === 'block'
        ? true
        : false;
};

const delay = (duration) => {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), duration);
    });
};

const offset = (el) => {
    var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

// getIndicesOf
// https://stackoverflow.com/questions/3410464/how-to-find-indices-of-all-occurrences-of-one-string-in-another-in-javascript
const getIndicesOf = (searchStr, str, caseSensitive) => {
    const searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    let startIndex = 0,
        index,
        indices = [];
    if (!caseSensitive) {
        str = str.toLowerCase();
        searchStr = searchStr.toLowerCase();
    }
    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
    }
    return indices;
};

// makeTitle
// https://stackoverflow.com/questions/8980842/convert-slug-variable-to-title-text-with-javascript
function makeTitle(slug) {
    var words = slug.split('-');

    for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }

    return words.join(' ');
}

// convertToSlug
// https://stackoverflow.com/questions/1053902/how-to-convert-a-title-to-a-url-slug-in-jquery
function convertToSlug(Text) {
    return Text.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export {
    debounce,
    isTouchDevice,
    disableScroll,
    enableScroll,
    isOdd,
    isElementXPercentInViewport,
    delay,
    offset,
    isDevice,
    makeTitle,
    convertToSlug
};
