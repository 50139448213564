class BasecampAnimation {
    constructor() {
        this.component = document.querySelector('#basecamp-animation');
        this.trigger = document.querySelector('#basecamp-animation-trigger');
        this.reset = document.querySelector('[data-hotspots="reset"]');
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.trigger.addEventListener(
            'click',
            function () {
                this.component.classList.add('active');
                setTimeout(() => {
                    this.component.classList.add('complete');
                }, 2000);
            }.bind(this)
        );

        this.reset.addEventListener(
            'click',
            function () {
                this.clear();
            }.bind(this)
        );
    }

    clear() {
        this.component.classList.add('reset');
        this.component.classList.remove('complete');
        this.component.classList.remove('active');
        this.component.querySelectorAll('.display').forEach((element) => {
            element.classList.remove('display');
        });
        this.component
            .querySelectorAll('.hotspot-active')
            .forEach((element) => {
                element.classList.remove('hotspot-active');
            });
        setTimeout(() => {
            this.component.classList.remove('reset');
        }, 5000);
    }
}

export { BasecampAnimation };
