export default class Hero {
    constructor() {
        this.video = document.getElementById('hero-video-mobile');
        this.playIcon = document.querySelector('.play-icon');
        this.init();
    }

    init() {
        this.video.load();
        this.playIcon.addEventListener('click', () => {
            if (this.video.paused) {
                this.video.play();
            } else {
                this.video.pause();
            }
        });
    }
}

export { Hero };
