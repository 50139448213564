import { SplitTypeAnimation } from '../split_type_animation';
import { Slick } from '../slick';
import { isDevice } from '../helpers';
import { Accordion } from '../accordion';
import { Draggable } from '../draggable';
import { AsideCombo } from '../aside-combo';
import { DrawPath } from '../draw_path';

class SingleCombo {
    constructor() {
        this.removed = false;
        this.init();
    }

    init() {
        this.initSliders();
        this.registerEventListeners();
        new SplitTypeAnimation().init();
        new Accordion();
        new Draggable().init();
        new AsideCombo().init();
    }

    registerEventListeners() {
        window.addEventListener(
            'resize',
            function (e) {
                this.initSliders();
            }.bind(this)
        );

        this.startAnimation();
    }

    startAnimation() {
        if (!!window.IntersectionObserver) {
            let observer = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            new DrawPath(
                                document.querySelector(
                                    '#flourish-card_trio-desktop path'
                                ),
                                { duration: 8000 }
                            ).init();
                            new DrawPath(
                                document.querySelector(
                                    '#flourish-card_trio-mobile path'
                                ),
                                { duration: 8000 }
                            ).init();
                            observer.unobserve(entry.target);
                        }
                    });
                },
                { rootMargin: '0px 0px -200px 0px' }
            );
            observer.observe(document.querySelector('#card_trio-slider'));
        }
    }

    initSliders() {
        new Slick("[data-slider='gallery_slider-image_only']", {
            adaptiveHeight: true,
            speed: 300,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            useTransform: false,
            dots: true,
            prevArrow: false,
            nextArrow: document.querySelector('.gallery_slider__slider'),
            dotsClass: 'custom_paging',
            touchThreshold: 250,
            customPaging: function (slider, i) {
                const curr = i + 1;
                if (i + 1 > 10) {
                    return '<span>' + curr + '</span>';
                } else {
                    const formattedNumber = curr.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    });
                    return '<span>' + formattedNumber + '</span>';
                }
            }
        });

        new Slick(".slider[data-slider='card-trio']", {
            speed: 0,
            variableWidth: true,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            useTransform: false,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,
            mobileFirst: true,
            initialSlide: 1
        });

        new Slick("[data-slider='testimonials_slider']", {
            adaptiveHeight: true,
            speed: 100,
            centerMode: true,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            useTransform: false,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,

            responsive: [
                {
                    breakpoint: 768,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '15px',
                    speed: 0
                }
            ]
        });
    }

    removeSlideFilter() {
        if (isDevice() && this.removed === false) {
            this.removed = jQuery(
                '[data-slider="testimonials_slider"] .slick-slide[data-slick-index="0"]'
            );
            jQuery('[data-slider="testimonials_slider"]').slick(
                'slickRemove',
                0
            );
        } else if (!isDevice()) {
            jQuery('[data-slider="testimonials_slider"]').slick(
                'slickAdd',
                this.removed,
                0,
                true
            );

            this.removed = false;
        }
    }
}

export { SingleCombo };
