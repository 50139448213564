class SliderTransform {
    constructor(slider) {
        this.slider = slider.querySelector('.slick-list');
        this.initialX = 0;
        this.slides = document.querySelectorAll(
            '.slick-slide:not(.slick-cloned)'
        );
        this.next = document.querySelector('[data-slick-index="1"]');
        this.direction;
        this.init();
    }

    init() {
        if (this.next) {
            this.next.classList.add('next');
        }

        this.regsiterEvents();
    }

    regsiterEvents() {
        this.slider.addEventListener(
            'mousedown',
            function (e) {
                const container = this.slider;
                const initialPosition = e.x;
                const action = (e) => this.dragAction(e, initialPosition);
                const end = (e) => {
                    this.dragEnd(e);
                    container.removeEventListener('mousemove', action);
                };

                container.addEventListener('mousemove', action, {
                    passive: true
                });

                container.addEventListener('mouseup', end, { passive: true });
                container.addEventListener('mouseleave', end, {
                    passive: true
                });
            }.bind(this)
        );
    }

    dragAction(e, initialPosition) {
        let distance = e.x - initialPosition;
        let curr = document.querySelector('.slick-active');
        let next = document.querySelector('.slick-slide.next');
        let prevIndex =
            parseInt(curr.getAttribute('data-slick-index')) == 0
                ? this.slides.length - 1
                : curr.getAttribute('data-slick-index') - 1;
        let prev = document.querySelector(`[data-slick-index="${prevIndex}"]`);

        curr.dataset.transforming = true;
        next.dataset.transforming = true;

        if (prev) {
            prev.dataset.transforming = true;
        }

        if (distance > 0) {
            curr.style.transform = `scale(${initialPosition / e.x})`;
            next.style.transform = `scale(1)`;
            if (prev) {
                prev.style.transform = `scale(${e.x / initialPosition})`;
            }
            this.direction = 'left';
        }

        if (distance < 0) {
            curr.style.transform = `scale(${e.x / initialPosition})`;
            next.style.transform = `scale(${initialPosition / e.x})`;
            this.direction = 'right';
        }
    }

    dragEnd() {
        document
            .querySelectorAll('[data-transforming="true"]')
            .forEach((slide) => {
                slide.setAttribute('data-transforming', false);
                slide.style.transform = 'scale(1)';
            });
    }
}

export { SliderTransform };
