import Accordion from '../accordion';
import { SplitTypeAnimation } from '../split_type_animation';

class Default {
    constructor() {}

    init() {
        new SplitTypeAnimation().init();

        if (document.querySelector('#accordion')) {
            new Accordion();
        }
    }
}

export { Default };
