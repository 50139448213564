import { Header } from './modules/header';
import { Footer } from './modules/footer';
import { Home } from './modules/pages/home';
import smoothscroll from 'smoothscroll-polyfill';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Parallax } from './modules/parallax';
import { Global } from './modules/global';
import { SingleActivity } from './modules/pages/single-activity';
import { ArchivePosts } from './modules/pages/archive-posts';
import { ArchiveActivity } from './modules/pages/archive-activity';
import { SingleCombo } from './modules/pages/single-combo';
import { GroupAdventures } from './modules/pages/group-adventures';
import { SeasonPass } from './modules/pages/season-pass';
import { HowToPrepare } from './modules/pages/how_to_prepare';
import { About } from './modules/pages/about';
import { CougarMountain } from './modules/pages/cougar_mountain';
import { Default } from './modules/pages/default';
import { PlayButton } from './modules/play_button';

class App {
    constructor(page, initialized) {
        this.page = page;
        this.initialized = initialized;
        gsap.registerPlugin(ScrollTrigger);
        new Global();

        if (this.initialized == false) {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', this.init());
            } else {
                this.init();
            }
        }
    }

    init() {
        smoothscroll.polyfill();
        new Header(document.getElementById('header-global'), true).init();
        new Footer().init();
        new Parallax(document.querySelectorAll('[data-parallax]'));
        new PlayButton().init();

        switch (true) {
            case this.page.classList.contains('home'):
                new Home();
                break;

            case this.page.classList.contains(
                'page-template-template-archive-activity'
            ):
                new ArchiveActivity();
                break;

            case this.page.classList.contains('page-template-template-about'):
                new About().init();
                break;

            case this.page.classList.contains('single-activities'):
                new SingleActivity();
                break;

            case this.page.classList.contains(
                'page-template-template-archive-posts'
            ):
                new ArchivePosts();
                break;

            case this.page.classList.contains('single-combos'):
                new SingleCombo();
                break;

            case this.page.classList.contains(
                'page-template-template-group_adventures'
            ):
                new GroupAdventures().init();
                break;

            case this.page.classList.contains(
                'page-template-template-season_pass'
            ):
                new SeasonPass().init();
                break;

            case this.page.classList.contains(
                'page-template-template-how_to_prepare'
            ):
                new HowToPrepare().init();
                break;

            case this.page.classList.contains(
                'page-template-template-cougar_mountain'
            ):
                new CougarMountain().init();
                break;

            case this.page.classList.contains('page-template-default'):
                new Default().init();
                break;

            case this.page.classList.contains('single-post'):
                new Default().init();
                break;
        }
    }
}

new App(document.querySelector('body'), true).init();
