import { Menus } from './menus';

class Header {
    constructor(container, scrollEvent) {
        this.container = container;
        this.scrollEvent = scrollEvent;
    }

    init() {
        if (this.scrollEvent) {
            this.registerScrollEvent(this.container);
        }
        new Menus(
            document.querySelector("[data-element='menu-btn']"),
            document.getElementById('primary'),
            this.container,
            true
        );
    }

    registerScrollEvent(element) {
        let lastScrollTop = Window.pageYOffset;

        // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
        document.addEventListener(
            'scroll',
            function () {
                // or window.addEventListener("scroll"....
                let st =
                    window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
                if (st > lastScrollTop) {
                    element.classList.add('scrolled');
                } else {
                    element.classList.remove('scrolled');
                }
                lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
            },
            false
        );
    }
}

export { Header };
