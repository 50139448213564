import { SplitTypeAnimation } from '../split_type_animation';
import { Slick } from '../slick';
import { isDevice } from '../helpers';
import { TabsActivity } from '../tabs_activity';
import { Accordion } from '../accordion';
import { AsideActivity } from '../aside-activity';
import { Draggable } from '../draggable';
import { DrawPath } from '../draw_path';

class SingleActivity {
    constructor() {
        this.removed = false;
        this.init();
    }

    init() {
        this.initSliders();
        this.registerEventListeners();
        new SplitTypeAnimation().init();
        new TabsActivity();
        new Accordion();
        new AsideActivity().init();
        new Draggable().init();
    }

    registerEventListeners() {
        window.addEventListener(
            'resize',
            function (e) {
                this.initSliders();
            }.bind(this)
        );

        this.startAnimation();
    }

    startAnimation() {
        if (!!window.IntersectionObserver) {
            let observer = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            new DrawPath(
                                document.querySelector(
                                    '#flourish-card_trio-desktop path'
                                ),
                                { duration: 8000 }
                            ).init();
                            new DrawPath(
                                document.querySelector(
                                    '#flourish-card_trio-mobile path'
                                ),
                                { duration: 8000 }
                            ).init();
                            observer.unobserve(entry.target);
                        }
                    });
                },
                { rootMargin: '0px 0px -200px 0px' }
            );
            observer.observe(document.querySelector('#card_trio-slider'));
        }
    }

    initSliders() {
        new Slick("[data-slider='gallery_slider-image_only']", {
            adaptiveHeight: true,
            speed: 300,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            useTransform: false,
            dots: true,
            prevArrow: false,
            nextArrow: document.querySelector('.gallery_slider__slider'),
            dotsClass: 'custom_paging',
            touchThreshold: 250,
            customPaging: function (slider, i) {
                const curr = i + 1;
                if (i + 1 > 10) {
                    return '<span>' + curr + '</span>';
                } else {
                    const formattedNumber = curr.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    });
                    return '<span>' + formattedNumber + '</span>';
                }
            }
        });
        new Slick(".slider[data-slider='card-trio']", {
            // speed: 0,
            // variableWidth: true,
            // centerMode: true,
            // slidesToShow: 1,
            // slidesToScroll: 1,
            // infinite: false,
            // useTransform: false,
            // dots: false,
            // prevArrow: false,
            // nextArrow: false,
            // touchThreshold: 250,
            // draggable: false,
            // swipe: true,
            // initialSlide: 1
            adaptiveHeight: true,
            speed: 300,
            variableWidth: true,
            centerMode: true,
            centerPadding: '15px',
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: 'unslick'
                }
            ]
        });

        new Slick("[data-slider='testimonials_slider']", {
            adaptiveHeight: true,
            speed: 100,
            centerMode: true,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            useTransform: false,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,

            responsive: [
                {
                    breakpoint: 768,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '15px',
                    speed: 0
                }
            ]
        });
    }
}

export { SingleActivity };
