class PlayButton {
    constructor() {
        this.videos = document.querySelectorAll('video');
    }

    init() {
        this.registerEvent();
    }

    registerEvent() {
        this.videos.forEach((video) => {
            video.addEventListener('click', function (e) {
                let playButton = e.target.nextElementSibling;

                if (e.target.paused) {
                    e.target.play();
                    playButton.classList.add('playing');
                } else {
                    e.target.pause();
                    playButton.classList.remove('playing');
                }
            });
        });
    }
}

export { PlayButton };
