import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default class Parallax {
	constructor(elements) {
		this.elements = elements;
		this.init();
	}

	init() {
		this.registerObserver(this.elements);
	}

	registerObserver(elements) {
		elements.forEach((element) => {
			const limit = element.dataset.limit;
			const start = element.dataset.start;

			ScrollTrigger.matchMedia({
				// desktop
				'(min-width: 901px)': function () {
					gsap.to(element, {
						scrollTrigger: {
							scrub: true,
							trigger: element,
							start: start,
							end: '+=200%',
						},
						y: (i, target) => {
							const curr =
								+ScrollTrigger.maxScroll(window) * target.dataset.speed;
							if (curr > limit) {
								return limit;
							} else {
								return curr;
							}
						},
						ease: 'none',
					});
				},
			});
		});
	}
}

export { Parallax };
