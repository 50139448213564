import { SplitTypeAnimation } from '../split_type_animation';
import { Select2 } from '../select2';
import { Hotspots } from '../hotspots';
import { Slick } from '../slick';
import { CardTrio } from '../card_trio';
import { Draggable } from '../draggable';
import { FyaEngine } from '../fya_engine';
import { SlickTransition } from '../slick_transition';
import { DrawPath } from '../draw_path';
import { Hero } from '../hero'

class Home {
    constructor() {
        this.removed = false;
        this.init();
    }

    init() {
        this.initSliders();
        this.startAnimation();

        new SplitTypeAnimation().init();
        new Hero()
        new Select2();
        new Hotspots();
        new CardTrio();
        new FyaEngine();
        new Draggable().init();
        new SlickTransition(
            "[data-slider='gallery_slider']",
            document.querySelector('.gallery_slider__slider')
        ).init();
    }

    startAnimation() {
        if (!!window.IntersectionObserver) {
            let observer = new IntersectionObserver(
                (entries, observer) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            new DrawPath(
                                document.querySelector(
                                    '#flourish-why_book-desktop path'
                                ),
                                { duration: 8000 }
                            ).init();
                            new DrawPath(
                                document.querySelector(
                                    '#flourish-why_book-mobile path'
                                ),
                                { duration: 8000 }
                            ).init();
                            observer.unobserve(entry.target);
                        }
                    });
                },
                { rootMargin: '0px 0px -200px 0px' }
            );
            observer.observe(document.querySelector('#card_trio'));
        }
    }

    initSliders() {
        new Slick("[data-slider='gallery_slider']", {
            adaptiveHeight: true,
            speed: 0,
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false,
            swipe: true,
            infinite: true,
            dots: true,
            prevArrow: false,
            nextArrow: document.querySelector('.gallery_slider__slider'),
            dotsClass: 'custom_paging',
            touchThreshold: 250,
            customPaging: function (slider, i) {
                const curr = i + 1;
                if (i + 1 > 10) {
                    return (
                        '<span class="h1 current">' +
                        curr +
                        '</span>' +
                        '<span class="h1 total">/' +
                        slider.slideCount +
                        '</span>'
                    );
                } else {
                    const formattedNumber = curr.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                    });
                    return (
                        '<span class="h1 current">' +
                        formattedNumber +
                        '</span>' +
                        '<span class="h1 total">/0' +
                        slider.slideCount +
                        '</span>'
                    );
                }
            },

            responsive: [
                {
                    breakpoint: 768,
                    variableWidth: true,
                    centerMode: true,
                    centerPadding: '15px',
                    speed: 0,
                    adaptiveHeight: false
                }
            ]
        });

        new Slick("[data-slider='news_updates']", {
            adaptiveHeight: true,
            speed: 300,
            variableWidth: true,
            centerMode: true,
            centerPadding: '15px',
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,

            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: 'unslick'
                }
            ]
        });

        new Slick("[data-slider='testimonials_slider']", {
            adaptiveHeight: true,
            speed: 100,
            variableWidth: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            useTransform: false,
            dots: false,
            prevArrow: false,
            nextArrow: false,
            touchThreshold: 250,
            centerMode: true,
            centerPadding: '15px',
            initialSlide: 1,

            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: 'unslick'
                }
            ]
        });
    }
}

export { Home };
