export default class Accordion {
    constructor() {
        this.trigger = document.querySelectorAll('[data-accordion="trigger"]');
        this.init();
    }

    init() {
        this.trigger.forEach((trig) => {
            this.registerClickEvent(trig);
        });
    }

    registerClickEvent(element) {
        element.addEventListener(
            'click',
            function (e) {
                e.target.classList.toggle('active');
            }.bind(this)
        );
    }
}

export { Accordion };
