class Draggable {
    constructor() {
        this.sliders = document.querySelectorAll('[data-draggable="true"]');
        this.isDown = false;
        this.startX;
        this.scrollLeft;
        this.mousedown;
    }

    init() {
        this.registerEvents(this.isDown, this.startX, this.scrollLeft);
    }

    registerEvents(isDown, startX, scrollLeft) {
        this.sliders.forEach((slider) => {
            slider.addEventListener(
                'mousedown',
                function (e) {
                    if (slider.dataset.draggableParent) {
                        slider = slider.querySelector(
                            '[data-draggable="child"]'
                        );
                    }
                    isDown = true;
                    slider.classList.add('active');
                    slider.classList.add('link-active');
                    startX = e.pageX - slider.offsetLeft;
                    scrollLeft = slider.scrollLeft;

                    this.mousedown = Date.now();
                }.bind(this)
            );

            slider.addEventListener(
                'mouseleave',
                function (e) {
                    if (slider.dataset.draggableParent) {
                        slider = slider.querySelector(
                            '[data-draggable="child"]'
                        );
                    }
                    isDown = false;
                    slider.classList.remove('active');
                }.bind(this)
            );
            slider.addEventListener(
                'mouseup',
                function (e) {
                    const linkable = slider.dataset.linkable;
                    if (slider.dataset.draggable != 'child') {
                        const elapsed = Date.now() - this.mousedown;
                        this.mousedown = undefined;
                        setTimeout(() => {
                            if (elapsed <= 100 && linkable) {
                                console.log('test');
                                const link = e.target.querySelector('a');
                                window.location.href = link;
                            }
                        }, 100);
                    }
                    isDown = false;
                    slider.classList.remove('active');
                }.bind(this)
            );
            slider.addEventListener(
                'mousemove',
                function (e) {
                    if (slider.dataset.draggableParent) {
                        slider = slider.querySelector(
                            '[data-draggable="child"]'
                        );
                    }

                    if (!isDown) return;
                    e.preventDefault();
                    const x = e.pageX - slider.offsetLeft;
                    const walk = (x - startX) * 1;
                    slider.scrollLeft = scrollLeft - walk;
                }.bind(this)
            );
        });
    }
}

export { Draggable };
