class InputsFyaEngine {
    constructor(input, output, container) {
        this.input = input;
        this.output = output;
        this.container = container;
        this.value = 0;
        this.init();
    }

    init() {
        this.registerEventListeners();
    }

    registerEventListeners() {
        if (this.input.length) {
            this.input.forEach((item) => {
                item.addEventListener('click', (e) => {
                    if (this.container.querySelector('.selected')) {
                        this.container
                            .querySelector('.selected')
                            .classList.remove('selected');
                    }

                    e.target.setAttribute('checked', true);
                    this.dragEnd(e.target);
                });
            });
        } else {
            this.input.addEventListener(
                'mousedown',
                function (e) {
                    const element = this.input;
                    const action = (event) => this.dragAction(event);
                    const end = (event) => {
                        this.dragEnd(event.target);
                        element.removeEventListener('mousemove', action);
                    };

                    element.classList.add('active');

                    element.addEventListener('mousemove', action, {
                        passive: true
                    });

                    element.addEventListener('mouseup', end, { passive: true });
                    element.addEventListener('mouseleave', end, {
                        passive: true
                    });
                }.bind(this)
            );
        }
    }

    dragAction(e) {
        const newValue = Math.round(this.value);
        const currIcon = this.container.querySelector(
            `.icon[data-index="${newValue}"]`
        );
        const currText = this.container.querySelector(
            `.output p[data-index="${newValue}"]`
        );
        const selected = this.container.querySelectorAll('.selected');

        this.value = Number(e.target.value);

        if (this.value === newValue) {
            currIcon.classList.add('selected');
            currText.classList.add('selected');
        } else if (selected) {
            selected.forEach((element) => {
                element.classList.remove('selected');
            });
        }
    }

    dragEnd(element) {
        new Promise((resolve, reject) => {
            const newValue = Math.round(element.value);
            const selected = this.container.querySelectorAll('.selected');

            element.classList.remove('active');
            element.value = newValue;

            if (selected) {
                selected.forEach((element) => {
                    element.classList.remove('selected');
                });
            }
            resolve(newValue);
        }).then((newValue) => {
            const type = element.getAttribute('type');
            const newIndex = type === 'range' ? newValue : newValue - 1;
            const currText = this.container.querySelector(
                `.output p[data-index="${newIndex}"]`
            );
            const currIcon = this.container.querySelector(
                `.icon[data-index="${newIndex}"]`
            );

            currIcon.classList.add('selected');
            currText.classList.add('selected');
        });
    }
}

export { InputsFyaEngine };
