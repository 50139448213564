class Select2 {
    constructor(options) {
        this.options = options;
        this.init();
    }

    init() {
        if (this.options && this.options.full === true) {
            jQuery('select').select2({ width: 'element' });
        } else {
            jQuery('select').select2();
        }
    }
}

export { Select2 };
