class Footer {
    constructor() {
        this.trigger = document.querySelector('[data-trigger="backToTop"]');
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        if (this.trigger) {
            this.trigger.addEventListener('click', () => {
                this.backToTop();
            });
        }
    }

    backToTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
}

export { Footer };
