import { BookingLinks } from './booking_links';

let DEFAULT_SEASON = ['#winter'];

class ArchiveActivityForm {
    constructor() {
        // Get the location.pathname value
        const currentPathname = location.pathname;
        var strippedPathname = currentPathname.replace(/\//g, '');
        if (strippedPathname === 'winter-park-pass') {
            DEFAULT_SEASON = ['#winter'];
        }
        this.checkedFlag = false;
        this.inputs = document.querySelectorAll('#filter-activity input');
        this.submit = document.querySelectorAll('[data-fya="submit"]');
        // this.season = document.querySelector(
        //     '[data-fya="submit"]:checked'
        // ).value;
        this.selectedSeason = 'on';
        if (!window.location.hash) {
            window.location.hash = DEFAULT_SEASON;
        }
        this.hashArray = window.location.hash.slice(1).split('-');
        this.init();
    }

    init() {
        this.registerClickEvents();
        this.hashURL();
        this.ajaxPostFunction().catch((error) => {
            console.log(error);
        });
    }

    registerClickEvents() {
        this.submit.forEach((button) => {
            button.addEventListener(
                'click',
                function (e) {
                    window.location.hash = null;
                    this.hashArray = [];
                    this.selectedSeason = e.target.value;
                    this.hashURL();
                    this.ajaxPostFunction().catch((error) => {
                        console.log(error);
                    });
                }.bind(this)
            );
        });
        document.querySelector('.archive-activity__filter').addEventListener(
            'click',
            function () {
                this.hideFilters();
            }.bind(this)
        );
    }

    hideFilters() {
        if (
            document
                .querySelector('.archive-activity__filter')
                .classList.contains('closed')
        ) {
            document
                .querySelector('.archive-activity__filter')
                .classList.remove('closed');
        } else {
            document
                .querySelector('.archive-activity__filter')
                .classList.add('closed');
        }
    }

    enableSummer() {
        document.querySelector(`input[name="summer"]`).checked = true;
        document.querySelector(`input[name="winter"]`).checked = false;
        document.querySelector(`input[name="all"]`).checked = false;
    }

    enableWinter() {
        document.querySelector(`input[name="summer"]`).checked = false;
        document.querySelector(`input[name="winter"]`).checked = true;
        document.querySelector(`input[name="all"]`).checked = false;
    }

    enableAll() {
        document.querySelector(`input[name="summer"]`).checked = true;
        document.querySelector(`input[name="winter"]`).checked = true;
        document.querySelector(`input[name="all"]`).checked = true;
    }

    buildHashUrl() {
        const hash = this.hashArray.join('-');

        if (hash) {
            switch (hash) {
                case 'summer-on':
                    this.enableSummer();
                    break;
                case 'summer':
                    this.enableSummer();
                    break;
                case 'winter-on':
                    this.enableWinter();
                    break;
                case 'winter':
                    this.enableWinter();
                    break;
                case 'summer-all-on':
                case 'winter-all-on':
                case 'all-on':
                case 'summer-winter-on':
                case 'summer-winter-all-on':
                    this.enableAll(true);
                    break;
                case 'summer-all':
                case 'winter-all':
                case 'all':
                case 'summer-winter':
                    this.enableAll(false);
                    break;
            }
        }
    }

    hashURL() {
        this.inputs.forEach((input) => {
            if (input.checked) {
                const value = input.value;
                if (!this.hashArray.includes(value)) {
                    this.hashArray.push(value);
                }
            }
        });

        if (this.selectedSeason === 'winter') {
            this.hashArray = this.hashArray.filter(
                (item) => item !== 'summer' && item !== 'all'
            );
            this.hashArray = ['winter', ...this.hashArray];
        }

        if (this.selectedSeason === 'summer') {
            this.hashArray = this.hashArray.filter(
                (item) => item !== 'winter' && item !== 'all'
            );
            if (
                this.hashArray.indexOf('on') !== -1 &&
                this.hashArray.length === 1
            ) {
                this.hashArray = ['summer', ...this.hashArray];
            }
        }

        if (this.selectedSeason === 'on') {
        }

        if (this.selectedSeason === 'all') {
            const indexToRemove = this.hashArray.indexOf('all');
            if (indexToRemove !== -1) {
                this.hashArray.splice(indexToRemove, 1);
            }
            this.hashArray = [...['summer', 'winter'], ...this.hashArray];
        }

        // remove any duplicates
        this.hashArray = this.hashArray.filter(
            (item, index) => this.hashArray.indexOf(item) === index
        );

        window.location.hash = this.hashArray.join('-');

        this.buildHashUrl();
    }

    ajaxPostFunction() {
        return new Promise((resolve, reject) => {
            var filter = jQuery('#filter-activity');
            var data = jQuery(filter).serializeArray();
            var resultsContainer = document.querySelector(
                '#filter-activity-results'
            );

            jQuery.ajax({
                url: filter.attr('action'),
                data: jQuery.param(data),
                type: filter.attr('method'),
                dataType: 'json',
                success: function (results) {
                    if (jQuery(resultsContainer).html() != results.html) {
                        jQuery(resultsContainer).fadeOut(100, function () {
                            jQuery(resultsContainer).html(results.html);
                            jQuery(resultsContainer).fadeIn();
                            new BookingLinks();
                            resolve(results);
                        });
                    }
                },

                error: function (error) {
                    reject(error);
                }
            });

            return false;
        });
    }
}

export { ArchiveActivityForm };
