class Values {
    constructor() {
        this.component = document.querySelector('[data-values="component"]');
        this.triggers = document.querySelectorAll('[data-trigger="values"]');
        this.curr = 0;
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.triggers.forEach((trigger) => {
            trigger.addEventListener(
                'click',
                function (e) {
                    const currIndex = parseInt(e.target.dataset.index);
                    this.advanceSlide();
                }.bind(this)
            );
        });
    }

    advanceSlide() {
        const currentSlide = this.component.querySelector(
            `[data-values="card"][data-index="${this.curr}"]`
        );

        const nextSlide = this.component.querySelector(
            `[data-values="card"][data-index="${this.curr + 1}"]`
        );

        const firstSlide = this.component.querySelector(
            '[data-values="card"][data-index="0"]'
        );

        currentSlide.classList.remove('active');

        if (nextSlide) {
            nextSlide.classList.add('active');
            setTimeout(() => {
                this.curr++;
            }, 500);
        } else {
            firstSlide.classList.add('active');
            setTimeout(() => {
                this.curr = 0;
            }, 500);
        }
    }
}

export { Values };
