import { InputsFyaEngine } from './fya_engine-inputs';
import { FyaEngineForm } from './fya_engine-form';
import { DrawPath } from './draw_path';

class FyaEngine {
    constructor() {
        this.component = document.querySelector('#popup-fya_engine');
        this.cards = document.querySelectorAll('.card[data-fya]');
        this.next = document.querySelectorAll('[data-fya="next"] .button__cta');
        this.back = document.querySelectorAll('[data-fya="back"] .link');
        this.currIndex = 0;
        this.open = document.querySelector('.fya_engine__cta .button');
        this.close = document.querySelector('[data-fya="close"]');
        this.results = document.querySelectorAll('[data-fya="submit"]');
        this.resultsDisplay = document.querySelector(
            '[data-fya="resultsContainer"]'
        );

        this.init();
    }

    init() {
        this.registerEvents();
        new FyaEngineForm();
    }

    registerComponents() {
        new InputsFyaEngine(
            document.querySelectorAll('.input--question1'),
            document.querySelector('[data-fya="text_output"]'),
            document.querySelector(`[data-fya="1"]`)
        );

        new InputsFyaEngine(
            document.querySelectorAll('.input--question2'),
            document.querySelector('[data-fya="text_output"]'),
            document.querySelector(`[data-fya="2"]`)
        );

        new InputsFyaEngine(
            document.querySelectorAll('.input--question3'),
            document.querySelector('[data-fya="text_output"]'),
            document.querySelector(`[data-fya="3"]`)
        );

        new InputsFyaEngine(
            document.querySelectorAll('.input--question4'),
            document.querySelector('[data-fya="text_output"]'),
            document.querySelector(`[data-fya="4"]`)
        );
    }

    registerEvents() {
        this.open.addEventListener(
            'click',
            function () {
                this.component.classList.add('open');
                document.querySelector('body').classList.add('fixed');
                new DrawPath(
                    document.querySelector('#flourish-desktop path')
                ).init();
                new DrawPath(
                    document.querySelector('#flourish-mobile-a path')
                ).init();
                new DrawPath(
                    document.querySelector('#flourish-mobile-b path')
                ).init();
            }.bind(this)
        );
        this.close.addEventListener(
            'click',
            function () {
                this.component.classList.remove('open');
                document.querySelector('body').classList.remove('fixed');
                this.restartEngine();
            }.bind(this)
        );
        this.next.forEach((button) => {
            button.addEventListener(
                'click',
                function (e) {
                    this.advanceSlide();
                }.bind(this)
            );
        });
        this.back.forEach((button) => {
            button.addEventListener(
                'click',
                function () {
                    this.advanceSlide('back');
                }.bind(this)
            );
        });

        this.results.forEach((button) => {
            button.addEventListener(
                'click',
                function () {
                    this.resultsSlide();
                }.bind(this)
            );
        });
    }

    advanceSlide(direction) {
        if (direction === 'back') {
            new Promise((resolve, reject) => {
                resolve(this.currIndex--);
            }).then(() => {
                this.animateText('back');
                this.component.setAttribute('data-curr', this.currIndex);
                setTimeout(() => {
                    this.toggleSlide(this.currIndex, 'back');
                }, 500);
            });
        } else {
            new Promise((resolve, reject) => {
                resolve(this.animateText());
            }).then(() => {
                this.currIndex++;
                this.component
                    .querySelector('[data-wrapper]')
                    .setAttribute('data-curr', this.currIndex);
                setTimeout(() => {
                    this.toggleSlide(this.currIndex);
                }, 500);
            });
        }
    }

    toggleSlide(index, direction) {
        if (direction === 'back') {
            setTimeout(() => {
                document
                    .querySelector(`[data-fya="${index + 1}"]`)
                    .classList.add('hidden');
                document
                    .querySelector(`[data-fya="${index}"]`)
                    .classList.remove('hidden');
            }, 500);
        } else {
            setTimeout(() => {
                document
                    .querySelector(`[data-fya="${index - 1}"]`)
                    .classList.add('hidden');
                document
                    .querySelector(`[data-fya="${index}"]`)
                    .classList.remove('hidden');
            }, 500);

            if (index === 1) {
                this.component
                    .querySelector('[data-animation="flourish"]')
                    .classList.add('fadeout');
            }
        }

        this.registerComponents();
    }

    animateText(back) {
        const currCard = document.querySelector(
            `[data-fya="${this.currIndex}"]`
        );
        const currCardText = currCard.querySelectorAll('.word');
        const currCardCTA = currCard.querySelector(
            '.popup-fya_engine__card__text--cta'
        );

        if (back === 'back') {
        }
        currCardText.forEach((item) => {
            item.classList.toggle('slide-out');
            currCardCTA.classList.toggle('fade-out');
        });
    }

    restartEngine() {
        this.component
            .querySelector('[data-wrapper]')
            .setAttribute('data-curr', 0);
        this.currIndex = 0;

        this.component.querySelectorAll('.fade-out').forEach((item) => {
            item.classList.remove('fade-out');
        });

        this.component.querySelectorAll('.slide-out').forEach((item) => {
            item.classList.remove('slide-out');
        });

        this.component.querySelectorAll('.card').forEach((item) => {
            if (item.classList.contains('initial')) {
                item.classList.remove('hidden');
            } else {
                item.classList.add('hidden');
            }
        });
    }

    resultsSlide() {
        this.cards.forEach((card) => {
            if (card.dataset.fya === 'results') {
                this.component.classList.add('results');
                card.classList.add('play');
                card.classList.remove('hidden');
            } else {
                this.component.classList.remove('results');
                card.classList.remove('play');
                card.classList.add('hidden');
            }
        });
    }
}

export { FyaEngine };
