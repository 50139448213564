class CardTrio {
    constructor() {
        this.component = document.querySelector('[data-cardtrio="component"]');
        this.cards = this.component.querySelectorAll('[data-cardtrio="card"]');
        this.init();
    }

    init() {
        this.registerEvents();
    }

    registerEvents() {
        this.cards.forEach((card) => {
            card.addEventListener(
                'click',
                function (e) {
                    const active = this.component.querySelector('.activated');
                    if (active) {
                        active.classList.remove('activated');
                    }
                    e.target.classList.toggle('activated');
                }.bind(this)
            );
        });
    }
}

export { CardTrio };
