import { ArchiveActivityForm } from '../archive-activity-form';
import { SplitTypeAnimation } from '../split_type_animation';
import { FyaEngine } from '../fya_engine';

class ArchiveActivity {
    constructor() {
        this.removed = false;
        this.init();
    }

    init() {
        new ArchiveActivityForm();
        new SplitTypeAnimation().init();
        new FyaEngine();
    }
}

export { ArchiveActivity };
