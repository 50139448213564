import { Select2 } from '../modules/select2';
import { isDevice } from './helpers';

class AsideActivity {
    constructor() {
        this.component = document.querySelector('#aside-book_activity');
        this.container = document.querySelector('.aside-book_activity__text');
        this.calendarContainer = document.querySelector(
            '.aside-book_activity__calendar'
        );
        this.tourButtons = document.querySelectorAll('[data-book="tour"]');
        this.tourSelect = document.querySelector('[data-book="select_tour"]');
        this.optionsButtons = document.querySelectorAll('[data-book="option"]');
        this.optionSelect = document.querySelectorAll(
            '[data-book="select_option"]'
        );
        this.tabButtons = document.querySelectorAll('[data-book="tab"]');
        this.prices = document.querySelectorAll('[data-book="prices"]');
        this.priceContainer = document.querySelectorAll(
            '[data-book="price_container"]'
        );
        this.open = this.component.querySelector('[data-book="open"]');
    }

    init() {
        this.registerEvents();
        new Select2({ full: true });
    }

    registerEvents() {
        // TOURS
        this.tourButtons.forEach((button) => {
            button.addEventListener(
                'click',
                function (e) {
                    this.tourButtonsEvent(e);
                }.bind(this)
            );
        });

        jQuery(this.tourSelect).on(
            'select2:select',
            function (e) {
                this.tourSelectEvent(e);
                this.tourButtonsEvent(e);
            }.bind(this)
        );

        // OPTIONS
        this.optionSelect.forEach((select) => {
            jQuery(select).on(
                'select2:select',
                function (e) {
                    this.optionsSelectEvent(e);
                }.bind(this)
            );
        });

        this.optionsButtons.forEach((button) => {
            button.addEventListener(
                'click',
                function (e) {
                    this.optionsButtonsEvent(e);
                }.bind(this)
            );
        });

        // Inside Tabs Content
        this.tabButtons.forEach((button) => {
            button.addEventListener(
                'click',
                function (e) {
                    this.insideTabButtonEvent(e);
                }.bind(this)
            );
        });

        // Mobile Open
        this.open.addEventListener(
            'click',
            function () {
                if (this.component.classList.contains('active')) {
                    document
                        .querySelector('body')
                        .classList.remove('aside-active');
                    this.component.classList.remove('active');
                    this.open.querySelector('p').innerText = 'Book Tour';
                    if (
                        !document
                            .querySelector('.select2-container')
                            .classList.contains('select2-container--disabled')
                    ) {
                        jQuery('.select--tour').val('all').trigger('change');
                    }
                    this.clear();
                } else {
                    document
                        .querySelector('body')
                        .classList.add('aside-active');
                    this.component.classList.add('active');
                    this.open.querySelector('p').innerText = 'close';
                }
            }.bind(this)
        );

        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.contentBoxSize) {
                    const optionsHeight = document.querySelector(
                        '.aside-book_activity__text'
                    );
                    const prices = document.querySelector(
                        '.aside-book_activity__text--prices.desktop.active'
                    );

                    if (isDevice('tablet')) {
                        this.calendarContainer.style.marginBottom = '100px';

                        if (prices) {
                            prices.style.top =
                                optionsHeight.offsetHeight +
                                entry.target.offsetHeight +
                                'px';
                        }
                    }
                }
            }
        });

        resizeObserver.observe(
            document.querySelector('.aside-book_activity__calendar')
        );
    }

    tourButtonsEvent(e) {
        const index = e.target.dataset.index;
        const activeArray = [this.container, this.component];

        // Elements to make active
        activeArray.forEach((element) => element.classList.add('active'));

        if (e.target.classList.contains('active')) {
            this.clear();
        } else {
            // Hide inactive tour buttons
            e.target.classList.add('active');
            this.tourButtons.forEach((button) => {
                if (button != e.target) {
                    button.classList.add('inactive');
                }
            });

            // If Options Activate Options Heading and Buttons
            if (this.optionsButtons.length > 0) {
                document.querySelector(`.headline[data-index='${index}']`)
                    ? document
                          .querySelector(`.headline[data-index='${index}']`)
                          .classList.add('active')
                    : null;

                this.optionsButtons.forEach((button) => {
                    if (button.dataset.index === index) {
                        button.classList.add('active');
                    }
                });
            } else {
                this.addCalendar(false, index);

                this.prices.forEach((price) => {
                    const pIndex = price.dataset.index;

                    if (price.classList.contains('active')) {
                        price.classList.remove('active');
                    } else {
                        if (pIndex === index) {
                            price.classList.add('active');
                        } else {
                            price.classList.remove('active');
                        }
                    }
                });

                this.priceContainer.forEach((price) => {
                    if (price.dataset.index === index) {
                        price.classList.add('selected');
                    } else {
                        price.classList.remove('selected');
                    }
                });
            }
        }
    }

    tourSelectEvent(e) {
        const index = e.target.value;
        const options = document.querySelector(
            `[data-fareharbor="buttons"][data-index="${index}"]`
        );

        this.clear();
        this.container.classList.add('active');
        this.component.classList.add('active');

        if (this.optionSelect.length > 0) {
            this.optionSelect.forEach((select) => {
                select.classList.add('active');
                select.classList.remove('inactive');
                select.dataset.tour_index = index;
            });
            options.classList.add('selected');
        } else {
            document
                .querySelector(`[data-book="tour"][data-index="${index}"]`)
                .classList.add('active');
            document
                .querySelector(`[data-book="tour"][data-index="${index}"]`)
                .classList.remove('inactive');
            this.prices.forEach((price) => {
                const pIndex = price.dataset.index;

                if (price.classList.contains('active')) {
                    price.classList.remove('active');
                } else {
                    setTimeout(() => {
                        if (pIndex === index) {
                            price.classList.add('active');
                        } else {
                            price.classList.remove('active');
                        }
                    }, 50);
                }
            });
            this.priceContainer.forEach((price) => {
                if (price.dataset.index === index) {
                    setTimeout(() => {
                        price.classList.add('selected');
                    }, 50);
                } else {
                    price.classList.remove('selected');
                }
            });
            this.removeCalendar();
            this.addCalendar(false, index);
        }
    }

    optionsSelectEvent(e) {
        const subindex = e.target.value;
        const index = e.target.dataset.index;

        // Toggle Calendars
        this.removeCalendar();
        this.addCalendar(subindex, index);

        this.prices.forEach((price) => {
            const pIndex = price.dataset.index;
            const pSubindex = price.dataset.subindex;

            if (price.classList.contains('active')) {
                price.classList.remove('active');
            } else {
                if (pIndex === index && pSubindex === subindex) {
                    price.classList.add('active');
                } else {
                    price.classList.remove('active');
                }
            }
        });

        if (e.target.classList.contains('selected')) {
            this.optionsButtons.forEach((button) => {
                this.removeCalendar();
            });
        } else {
            e.target.classList.add('selected');
            this.addCalendar(subindex, index);
            this.optionsButtons.forEach((button) => {
                if (button != e.target) {
                    button.classList.remove('active');
                    button.classList.add('inactive');
                }
            });
            this.priceContainer.forEach((price) => {
                if (
                    price.dataset.index === index &&
                    price.dataset.subindex === subindex
                ) {
                    price.classList.add('selected');
                }
            });

            document
                .querySelector(`[data-book="tour"][data-index="${index}"]`)
                .classList.add('active');
            document
                .querySelector(`[data-book="tour"][data-index="${index}"]`)
                .classList.remove('inactive');
            document
                .querySelector(`[data-book="option"][data-index="${index}"]`)
                .classList.add('active');
            document
                .querySelector(`[data-book="option"][data-index="${index}"]`)
                .classList.add('selected');
            document
                .querySelector(
                    `[data-book="option"][data-subindex="${subindex}"]`
                )
                .classList.remove('inactive');
        }
    }

    optionsButtonsEvent(e) {
        const index = e.target.dataset.index;
        const subindex = e.target.dataset.subindex;

        this.prices.forEach((price) => {
            const pIndex = price.dataset.index;
            const pSubindex = price.dataset.subindex;

            if (price.classList.contains('active')) {
                price.classList.remove('active');
            } else {
                if (pIndex === index && pSubindex === subindex) {
                    price.classList.add('active');
                } else {
                    price.classList.remove('active');
                }
            }
        });

        if (e.target.classList.contains('selected')) {
            this.component
                .querySelectorAll('.selected')
                .forEach((element) => element.classList.remove('selected'));

            this.optionsButtons.forEach((button) => {
                this.removeCalendar();
                if (button != e.target && button.dataset.index === index) {
                    button.classList.add('active');
                    button.classList.remove('inactive');
                }
            });
        } else {
            e.target.classList.add('selected');
            this.addCalendar(subindex, index);
            this.optionsButtons.forEach((button) => {
                if (button != e.target) {
                    button.classList.remove('active');
                    button.classList.add('inactive');
                }
            });
            this.priceContainer.forEach((price) => {
                if (
                    price.dataset.index === index &&
                    price.dataset.subindex === subindex
                ) {
                    price.classList.add('selected');
                }
            });
        }
    }

    insideTabButtonEvent(e) {
        const index = e.target.dataset.index;
        const subindex = e.target.dataset.subindex;
        const options = document.querySelector(
            `[data-fareharbor="buttons"][data-index="${index}"]`
        );

        this.clear();

        this.container.classList.add('active');
        this.component.classList.add('active');

        this.tourButtons.forEach((button) => {
            if (button.dataset.index === index) {
                button.classList.add('active');
            } else {
                button.classList.add('inactive');
            }
        });

        if (index) {
            jQuery('[data-book="select_tour"]').val(index).trigger('change');
        }

        jQuery('[data-book="select_option"]').val(subindex).trigger('change');

        if (this.optionSelect.length > 0) {
            this.optionSelect.forEach((select) => {
                select.classList.add('active');
                select.dataset.tour_index = index;
            });
            options.classList.add('selected');
        } else {
            this.prices.forEach((element) => {
                element.classList.add('active');
            });
            this.priceContainer.forEach((price) => {
                if (price.dataset.index === index) {
                    price.classList.add('selected');
                } else {
                    price.classList.remove('selected');
                }
            });
        }

        if (this.optionsButtons.length > 0) {
            document
                .querySelector(`.headline[data-index='${index}']`)
                .classList.add('active');
            this.optionsButtons.forEach((button) => {
                if (
                    button.dataset.subindex === subindex &&
                    button.dataset.index === index
                ) {
                    this.addCalendar(e.target.dataset.subindex);
                    button.classList.add('active', 'selected');
                } else {
                    button.classList.remove('active');
                    button.classList.add('inactive');
                }
            });
        }

        this.prices.forEach((price) => {
            const pIndex = price.dataset.index;
            const pSubindex = price.dataset.subindex;

            if (price.classList.contains('active')) {
                price.classList.remove('active');
            } else {
                if (pIndex === index && pSubindex === subindex) {
                    price.classList.add('active');
                } else {
                    price.classList.remove('active');
                }
            }
        });

        this.priceContainer.forEach((price) => {
            if (
                price.dataset.index === index &&
                price.dataset.subindex === subindex
            ) {
                price.classList.add('selected');
            }
        });
    }

    addCalendar(subindex, index) {
        document
            .querySelectorAll('[data-fareharbor="calendar"]')
            .forEach((calendar) => {
                calendar.classList.add('inactive');
            });

        setTimeout(() => {
            if (subindex && index) {
                document
                    .querySelector(
                        `[data-fareharbor="calendar"][data-index="${index}"][data-subindex="${subindex}"]`
                    )
                    .classList.add('active');
                document
                    .querySelector(
                        `[data-fareharbor="calendar"][data-index="${index}"][data-subindex="${subindex}"]`
                    )
                    .classList.remove('inactive');
            } else if (subindex) {
                document
                    .querySelector(
                        `[data-fareharbor="calendar"][data-subindex="${subindex}"]`
                    )
                    .classList.add('active');
                document
                    .querySelector(
                        `[data-fareharbor="calendar"][data-subindex="${subindex}"]`
                    )
                    .classList.remove('inactive');
            } else {
                document
                    .querySelector(
                        `[data-fareharbor="calendar"][data-index="${index}"]`
                    )
                    .classList.add('active');
                document
                    .querySelector(
                        `[data-fareharbor="calendar"][data-index="${index}"]`
                    )
                    .classList.remove('inactive');
            }
        }, 100);
    }

    removeCalendar() {
        if (document.querySelector(`[data-fareharbor="calendar"].active`)) {
            document
                .querySelector(`[data-fareharbor="calendar"].active`)
                .classList.remove('active');
        }
    }

    clear() {
        const activeArray = [
            this.container,
            this.component,
            Array.from(this.component.querySelectorAll('.selected')),
            Array.from(this.component.querySelectorAll('.active')),
            Array.from(this.component.querySelectorAll('.inactive'))
        ];

        const cleanArray = activeArray.filter((n) => {
            return n !== null && typeof n !== 'undefined';
        });

        // Elements to clear active
        cleanArray.forEach((element) => {
            if (Array.isArray(element)) {
                element.forEach((item) => {
                    item.classList.remove('active');
                    item.classList.remove('inactive');
                    item.classList.remove('selected');
                });
            } else {
                element.classList.remove('active');
                element.classList.remove('inactive');
                element.classList.remove('selected');
            }
        });
    }
}

export { AsideActivity };
